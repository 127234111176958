import { styled } from "@mui/material/styles";
import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";

const ProjectListAccordionItemSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowRightSharpIcon color="primary" />} {...props} />
))(() => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  padding: "8px 16px",
  backgroundColor: "white",
}));

export default ProjectListAccordionItemSummary;
