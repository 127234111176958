import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import { Backdrop, Box, CircularProgress, Pagination, TextField } from "@mui/material";
import proj4 from "proj4";
import ErrorIcon from "@mui/icons-material/Error";
import useOrthophotoMapStore from "../../../../pages/OrthophotoMapPage/orthophoto-map.store";
import { ResourceEntity } from "../../../../clients/MainApiClient/dtos/entities";
import MainApiClient from "../../../../clients/MainApiClient";
import ResourceListItem, { DenseChip } from "../../../../design/atoms/ResourceListItem";
import DenseIconButton from "../../../../design/atoms/buttons/DenseIconButton";
import { MapIcon } from "../../../../design/atoms/icons";
import {
  dense_icon_button_height,
  dense_icon_button_width,
} from "../../../../design/tokens/spacing";
import useResourcesStore from "./stores/resource.store";
import MoreBtn from "./components/MoreBtn";
import InnerLoading from "../../../InnerLoading";

export default function TiffResourceList() {
  const navigate = useNavigate();
  const { setOrthoMapUrl, setZoom, setCenter, setExtent } = useOrthophotoMapStore();
  const { fetchResources, setPage, resources, page, lastPage, onResourceLoading, query, setQuery } =
    useResourcesStore();

  const onPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    fetchResources();
    // react-hooks/exhaustive-deps
    // eslint-disable-next-line
  }, []);

  const handleClick = (resource: ResourceEntity) => {
    setOrthoMapUrl(`/api/resources/${resource.id}/tiles/{z}/{x}/{-y}`);
    if (resource.orthophoto && resource.orthophoto.center.coordinates.length === 2) {
      //
      const center = resource.orthophoto.center.coordinates as [number, number];
      const center2 = proj4(`EPSG:4326`, `EPSG:3857`, center);
      setCenter(center2);
      //
      const bbox = resource.orthophoto.bbox.coordinates;
      const bboxPolygon = bbox[0].map((coord) => proj4(`EPSG:4326`, `EPSG:3857`, coord));
      const bboxWidth = Math.abs(bboxPolygon[2][0] - bboxPolygon[0][0]);
      const bboxHeight = Math.abs(bboxPolygon[2][1] - bboxPolygon[0][1]);
      const maxDimension = Math.max(bboxWidth, bboxHeight);
      const zoom = Math.floor(20 - Math.log2(maxDimension / 128));

      //
      setZoom(zoom > 18 ? 18 : zoom);
      const extent = [
        bboxPolygon[0][0],
        bboxPolygon[0][1],
        bboxPolygon[2][0],
        bboxPolygon[2][1],
      ] as [number, number, number, number];
      setExtent(extent);
    }
    navigate("/orthophoto-map");
  };

  // const [q, setQ] = React.useState(query);
  useEffect(() => {
    fetchResources();
  }, [fetchResources, query]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          background: "white",
          padding: 1,
          width: "100%",
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <TextField
          sx={{ width: "100%" }}
          variant="filled"
          label="리소스 찾기"
          size="small"
          value={query}
          onChange={(e) => {
            // console.log(e.target.value);
            const q = e.target.value;
            setPage(1);
            setQuery(q);
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: "100%",
          position: "relative",
        }}
      >
        <InnerLoading loading={onResourceLoading} />
        {resources.map((resource) => (
          <ResourceListItem key={resource.id}>
            <DenseChip type={resource.file_name.split(".").pop() || "unknown"} />
            <Box>
              <div style={{ userSelect: "none" }}>{resource.name}</div>
              <div style={{ userSelect: "none", fontSize: "14px", color: "#565656" }}>
                {resource.file_name}
              </div>
            </Box>
            <div style={{ flexGrow: 1 }} />
            {resource.orthophoto?.status === "done" && (
              <DenseIconButton
                color="primary"
                onClick={() => {
                  handleClick(resource);
                }}
              >
                <MapIcon />
              </DenseIconButton>
            )}
            {resource.orthophoto?.status === "pending" && (
              <DenseIconButton disabled color="primary">
                <MapIcon />
              </DenseIconButton>
            )}
            {resource.orthophoto?.status === "processing" && (
              <CircularProgress
                style={{
                  width: dense_icon_button_height - 10,
                  height: dense_icon_button_width - 10,
                }}
              />
            )}
            {resource.orthophoto?.status === "failed" && <ErrorIcon color="error" />}
            <MoreBtn resource={resource} />
          </ResourceListItem>
        ))}
      </Box>
      <Box sx={{ flex: "none", flexGrow: 1, alignSelf: "stretch" }} />
      <Box
        sx={{
          paddingY: 1,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "sticky",
          bottom: 0,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
        }}
      >
        <Pagination count={lastPage} page={page} onChange={onPageChange} />
      </Box>
    </Box>
  );
}
