import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Backdrop, CircularProgress, DialogTitle, TextField } from "@mui/material";
import MainApiClient from "../../../../clients/MainApiClient";
import useResourcesStore from "./stores/resource.store";
import InnerLoading from "../../../InnerLoading";

export default function UploadResourceDialog(p: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { open, setOpen } = p;
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

  const [onCreatingResource, setOnCreatingResource] = React.useState(false);

  const { fetchResources } = useResourcesStore();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setSelectedFile(file);
    }
  };

  const client = React.useMemo(() => {
    return new MainApiClient();
  }, []);

  const [name, setName] = React.useState("");

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("파일을 선택해주세요.");
      return;
    }

    setOnCreatingResource(true);
    client
      .createResource({ name, file: selectedFile })
      .then(() => {
        setOpen(false);
        fetchResources();
        setName("");
        setSelectedFile(null);
      })
      .finally(() => {
        setOnCreatingResource(false);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        alert("업로드 중 오류가 발생했습니다.");
      });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle id="alert-dialog-title">리소스 업로드</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            현재 사용 가능한 파일 형식은 위치 정보가 존재하는 tiff 파일 뿐입니다.
          </DialogContentText>
          <div>
            <TextField
              variant="filled"
              label="이름"
              sx={{ width: "100%" }}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div>
            <input type="file" accept=".tif,.tiff" onChange={handleFileChange} />
            {selectedFile && <p>Selected File: {selectedFile.name}</p>}
          </div>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "transparent" }}>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            취소
          </Button>
          <Button
            disabled={!selectedFile || !name}
            variant="contained"
            onClick={() => {
              handleUpload();
            }}
          >
            업로드
          </Button>
        </DialogActions>
        <InnerLoading loading={onCreatingResource} />
      </Dialog>
    </React.Fragment>
  );
}
