import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Box, DialogTitle, TextField } from "@mui/material";
import MainApiClient from "../../../clients/MainApiClient";
import useProjectsStore from "../stores/projects.store";

const CreateProjectDialog = (p: { open: boolean; setOpen: (open: boolean) => void }) => {
  const { open, setOpen } = p;

  const [name, setName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");

  const mainApiClient = React.useMemo(() => new MainApiClient(), []);

  const { fetchProjects, setProject, projects } = useProjectsStore();

  const createProject = async () => {
    try {
      mainApiClient
        .createProject({
          name,
          description,
        })
        .then((r) => {
          setName("");
          setDescription("");
          setProject(r.project);
        });
      setOpen(false);
    } catch (error) {
      console.error("Failed to create project", error);
    }
  };

  const preventAndStopPropagation = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {
          setOpen(false);
        }}
        onClick={(e) => {
          preventAndStopPropagation(e);
        }}
      >
        <DialogTitle id="alert-dialog-title">프로젝트 생성</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              variant="filled"
              label="프로젝트 이름"
              sx={{ width: "100%" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              variant="filled"
              label="프로젝트 설명"
              sx={{ width: "100%" }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "transparent" }}>
          <Button
            onClick={(e) => {
              setOpen(false);
              preventAndStopPropagation(e);
            }}
          >
            취소
          </Button>
          <Button
            disabled={!name || !description}
            variant="contained"
            onClick={(e) => {
              // setOpen(false);
              createProject();
              preventAndStopPropagation(e);
            }}
          >
            생성
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateProjectDialog;
