import React from "react";
import TaskAccordion from "./TaskAccordion";
import useProjectsStore from "../../stores/projects.store";
import useTasksStore from "./stores/tasks.store";

export default function TasksList() {
  const { project } = useProjectsStore();
  const { tasks, fetchTasks } = useTasksStore();

  React.useEffect(() => {
    if (project === null) return;
    fetchTasks(project.id);
    // eslint-disable-next-line
  }, [project]);

  return (
    <>
      {tasks.map((task) => (
        <TaskAccordion key={task.id} task={task} />
      ))}
    </>
  );
}
