import { createTheme } from "@mui/material/styles";
import { negative, primary } from "./design/tokens/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      // light: main값을 통해 계산됨
      // dark: main값을 통해 계산됨
      // contrastText: main값을 통해 계산됨
    },
    error: {
      main: negative,
    },
  },
});

export default theme;
