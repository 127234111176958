import { useEffect, useState } from "react";
import ConfidenceAccordion from "./ConfidenceAccordion";
import CategoryAccordion from "./CategoryAccordion";
import useSwipeCommonStore from "../../../stores/useSwipeCommon.store";

export default function FeaturesAccordion() {
  const { task } = useSwipeCommonStore();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!task) return;
    if (task.type.id === 2) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [task]);

  return (
    <>
      <ConfidenceAccordion />
      {show && <CategoryAccordion />}
    </>
  );
}
