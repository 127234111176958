import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import useSwipeCommonStore from "../stores/useSwipeCommon.store";
import useSwipeLayerStore from "../stores/useSwipeLayer.store";
import TileLayer1FormControlLabel from "./components/TileLayer1FormControlLabel";
import TileLayer2FormControlLabel from "./components/TileLayer2FormControlLabel";
import FeaturesAccordion from "./components/FeaturesAccordion";
import SelectedFeatureAccordion from "./components/SelectedFeatureAccordion";

export default function LayerAccordions() {
  // 탐지 결과
  const [showDetection, setShowDetection] = React.useState(true);
  const { vectorLayer } = useSwipeLayerStore();
  //
  const { task } = useSwipeCommonStore();

  React.useEffect(() => {
    if (!vectorLayer) return;
    if (showDetection) {
      vectorLayer.set("visible", true);
      // vectorLayer.changed();
    } else {
      vectorLayer.set("visible", false);
    }
  }, [vectorLayer, showDetection]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: 20,
        left: 20,
        maxWidth: 230,
      }}
    >
      <FeaturesAccordion />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          레이어
        </AccordionSummary>
        <AccordionDetails>
          {task?.inputs[0] && <TileLayer1FormControlLabel tile={task.inputs[0].name} />}
          {task?.inputs[1] && <TileLayer2FormControlLabel tile={task.inputs[1].name} />}
          <FormControlLabel
            control={
              <Checkbox
                checked={showDetection}
                onChange={(e) => {
                  setShowDetection(e.target.checked);
                }}
              />
            }
            label="변화탐지 객체"
          />
        </AccordionDetails>
      </Accordion>
      {task?.type.id === 2 && <SelectedFeatureAccordion />}
    </Box>
  );
}
