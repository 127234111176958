import { persist } from "zustand/middleware";
import { create } from "zustand";
import MainApiClient from "../../../clients/MainApiClient";
import { ProjectEntity } from "../../../clients/MainApiClient/dtos/entities";

const client = new MainApiClient();

interface ProjectStore {
  projects: ProjectEntity[];
  setProjects: (newProjects: ProjectEntity[]) => void;
  onProjectsLoading: boolean;
  page: number;
  setPage: (page: number) => void;
  lastPage: number;
  setLastPage: (page: number) => void;
  setOnProjectsLoading: (loading: boolean) => void;
  fetchProjects: () => Promise<void>;

  query: string;
  setQuery: (query: string) => void;

  // selected project
  project: ProjectEntity | null;
  setProject: (project: ProjectEntity) => void;

  deleteProject: (project: ProjectEntity) => Promise<void>;
}

const useProjectsStore = create<ProjectStore>()(
  persist(
    (set, get) => ({
      project: null,
      setProject: (project: ProjectEntity) => set({ project }),
      projects: [],
      setProjects: (newProjects: ProjectEntity[]) => set({ projects: newProjects }),
      onProjectsLoading: false,
      page: 1,
      setPage: (page: number) => {
        set({ page });
        get().fetchProjects();
      },
      lastPage: 1,
      setLastPage: (lastPage: number) => set({ lastPage }),
      setOnProjectsLoading: (loading: boolean) => set({ onProjectsLoading: loading }),
      fetchProjects: () => {
        set({ onProjectsLoading: true });
        const { page } = get();
        return client
          .readProjectList({ page, per_page: 5, q: get().query })
          .then(({ projects, last_page }) => {
            set({ projects });
            set({ lastPage: last_page });
          })
          .finally(() => {
            set({ onProjectsLoading: false });
          });
      },
      query: "",
      setQuery: (query: string) => {
        set({ query });
        set({ page: 1 });
        get().fetchProjects();
      },
      deleteProject: (project: ProjectEntity) => {
        set({ onProjectsLoading: true });
        return client
          .deleteProject(project)
          .then(() => {
            get().fetchProjects();
            set({ projects: get().projects.filter((p) => p.id !== project.id) });
          })
          .finally(() => {
            set({ onProjectsLoading: false });
            get().fetchProjects();
          });
      },
    }),
    {
      name: "projects-store",
      partialize: (state) => ({
        page: state.page,
        lastPage: state.lastPage,
        project: state.project,
      }),
    },
  ),
);

export default useProjectsStore;
