import { create } from "zustand";
import { persist } from "zustand/middleware";

interface SelectedProject {
  p1: boolean;
  p2: boolean;
  setP1(p1: boolean): void;
  setP2(p2: boolean): void;
}

const useExtensionStore = create<SelectedProject>()(
  persist(
    (set) => ({
      p1: false,
      p2: false,
      setP1: (p1: boolean) => set({ p1 }),
      setP2: (p2: boolean) => set({ p2 }),
    }),
    {
      name: "selected-project-storage", // unique name
    },
  ),
);

export default useExtensionStore;
