import { Accordion, AccordionDetails, AccordionSummary, Box, Slider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useConfidenceStore from "./confidence.store";

function valuetext(value: number) {
  return `${value}%`;
}

export default function ConfidenceAccordion() {
  const { conf, handleChangeConf } = useConfidenceStore();

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        추론 신뢰도
      </AccordionSummary>
      <AccordionDetails>
        <Slider
          value={conf}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
          onChange={handleChangeConf}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {conf[0]}-{conf[1]}%
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
