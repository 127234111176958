import { create } from "zustand";
import { TaskEntity } from "../../../clients/MainApiClient/dtos/entities";

export type MODE = "swipev" | "swipeh" | "compare" | "view1" | "view2";
interface SwipeCommonState {
  // 도구 사용 여부
  useTool: boolean;
  setUseTool: (useTool: boolean) => void;
  // 선택된 Task
  task: TaskEntity | null;
  setTask: (task: TaskEntity) => void;
  // mode
  mode: MODE;
  setMode: (mode: MODE) => void;
}

const useSwipeCommonStore = create<SwipeCommonState>((set) => ({
  useTool: true,
  setUseTool: (useTool) => set({ useTool }),

  //
  task: null,
  setTask: (task) => set({ task }),
  //
  mode: "swipev",
  setMode: (mode) => set({ mode }),
}));

export default useSwipeCommonStore;
