import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import useSwipeCommonStore from "../../stores/useSwipeCommon.store";
import useVectorSourceStore from "../../stores/useVectorSource.store";

function FooBox() {
  const [type, setType] = useState<"create" | "edit" | null>(null);
  const {
    selectedFeature: feature,
    addFeature,
    updateFeature,
    deleteFeature,
    select,
  } = useVectorSourceStore();

  useEffect(() => {
    console.log("type", feature);

    if (!feature) {
      setType(null);
      return;
    }
    const tempPo = feature.getProperties();
    const id = tempPo.fid;
    if (id) setType("edit");
    else setType("create");
  }, [feature, setType]);

  const [po, setPo] = useState<{
    ol_uid?: string;
    fid?: string | number;
    Category?: string;
    Category_N?: string;
    confidence?: number;
  }>({});

  useEffect(() => {
    if (!feature) return;
    const tempPo = feature.getProperties();
    delete tempPo.geometry;
    setPo({ ...{ Category: undefined, Category_N: undefined, confidence: 99 }, ...tempPo });
  }, [feature]);

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    const tempPo = { ...po };

    tempPo.Category = value;
    switch (value) {
      case "1":
        tempPo.Category_N = "신축";
        break;
      case "2":
        tempPo.Category_N = "철거";
        break;
      case "3":
        tempPo.Category_N = "갱신";
        break;
      case "4":
        tempPo.Category_N = "색변화";
        break;
      default:
        break;
    }

    setPo(tempPo);
  };

  function ClassBox() {
    return (
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="body1" sx={{ flex: 1, flexGrow: 1 }}>
          클래스
        </Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={po.Category}
          label="Age"
          size="small"
          sx={{ flex: 1, flexGrow: 1, textAlign: "right" }}
          onChange={handleChange}
        >
          <MenuItem value={"1"}>신축</MenuItem>
          <MenuItem value={"2"}>철거</MenuItem>
          <MenuItem value={"3"}>갱신</MenuItem>
          <MenuItem value={"4"}>색변화</MenuItem>
        </Select>
      </Box>
    );
  }

  function ConfidenceBox() {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="body1" sx={{ flex: 1, flexGrow: 1 }}>
          신뢰도
        </Typography>
        <TextField
          disabled
          sx={{ flex: 1, textAlign: "right" }}
          variant="outlined"
          size="small"
          inputProps={{ min: 0, max: 100, inputMode: "numeric", pattern: "[0-9]*" }}
          value={po.confidence}
          InputProps={{
            style: { textAlign: "right" },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          onChange={(e) => {
            setPo({ ...po, confidence: Number(e.target.value) });
          }}
        />
      </Box>
    );
  }

  function DeleteButton() {
    return (
      <Button
        size="small"
        variant="text"
        disableElevation
        onClick={(e) => {
          if (!feature) return;
          deleteFeature(feature);
          if (select) select.getFeatures().clear();
        }}
      >
        삭제
      </Button>
    );
  }

  const editXElement = (
    <>
      <AccordionDetails>
        <Typography variant="h6">수정(id:{String(po.fid)})</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: 1, gap: 2 }}>
          <ClassBox />
          <ConfidenceBox />
        </Box>
      </AccordionDetails>
      <AccordionActions>
        <DeleteButton />
        <Button
          size="small"
          variant="contained"
          disableElevation
          onClick={(e) => {
            if (!feature) return;
            feature.setProperties({
              Category: po.Category,
              Category_N: po.Category_N,
            });
            updateFeature(feature);
            if (select) select.getFeatures().clear();
          }}
        >
          수정 적용
        </Button>
      </AccordionActions>
    </>
  );

  const createElement = (
    <>
      <AccordionDetails>
        <Typography variant="h6">객체 추가</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: 1, gap: 2 }}></Box>
        <ClassBox />
        <ConfidenceBox />
      </AccordionDetails>
      <AccordionActions>
        <DeleteButton />
        <Button
          size="small"
          variant="contained"
          disableElevation
          onClick={(e) => {
            if (!feature) return;
            feature.setProperties({
              Category: po.Category,
              Category_N: po.Category_N,
              confidence: po.confidence,
            });
            addFeature(feature);
            if (select) select.getFeatures().clear();
          }}
        >
          수정 적용
        </Button>
      </AccordionActions>
    </>
  );

  switch (type) {
    case "create":
      return createElement;
    case "edit":
      return editXElement;
    default:
      return (
        <>
          <Typography variant="h6">선택된 객체 없음</Typography>
        </>
      );
  }
}

export default function SelectedFeatureAccordion() {
  // 도구 사용 여부
  const { useTool, setUseTool } = useSwipeCommonStore();

  return (
    <>
      <Accordion
        expanded={useTool}
        onChange={(e, x) => {
          setUseTool(x);
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>객체 편집 도구</AccordionSummary>
        <Divider />
        <FooBox />
      </Accordion>
    </>
  );
}
