import { Feature, getUid } from "ol";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { create } from "zustand";
import { Fill, Stroke, Style } from "ol/style";
import Bar from "ol-ext/control/Bar";
import EditBar, { Interactions } from "ol-ext/control/EditBar";
import { Draw, Select } from "ol/interaction";
import ModifyFeature from "ol-ext/interaction/ModifyFeature";
import { Transform } from "stream";
import Button from "ol-ext/control/Button";
import UndoRedo from "ol-ext/interaction/UndoRedo";

function getColor(index: number) {
  const colors = [
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#d62728",
    "#9467bd",
    "#8c564b",
    "#e377c2",
    "#7f7f7f",
    "#bcbd22",
    "#17becf",
  ];
  return colors[index % colors.length];
}

export interface FeatureCategory {
  key: string;
  name: string;
  // count: number;
  // checked: boolean;
  style: Style;
}

interface VectorSourceState {
  // url 설정
  setUrl: (url: string) => void;
  // 공유 되는 source
  vectorSource: VectorSource<Feature> | null;
  features: Feature[];
  // feature
  getFeature: (ol_uid: string) => Feature | undefined;
  addFeature: (feature: Feature) => void;
  updateFeature: (feature: Feature) => void;
  deleteFeature: (feature: Feature) => void;
  // 카테고리
  categories: FeatureCategory[];
  // 툴바
  mainBar: Bar | null;
  undoInteraction: UndoRedo | null;
  //
  selectedFeature: Feature | null;
  select: Select | null;
}

const useVectorSourceStore = create<VectorSourceState>()((set, get) => {
  const vectorSource: VectorSource<Feature> | null = null;

  const updateFeatures = (features: Feature[]) => {
    if (features.length === 0) return;

    const categorizedFeatures = features
      .map((f) => {
        return {
          fid: f.get("fid"),
          key: f.get("Category"),
          name: f.get("Category_N"),
        };
      })
      .reduce<{ [key: string]: FeatureCategory }>((acc, cur) => {
        if (!acc[cur.key]) {
          const style = new Style({
            fill: new Fill({
              color: `${getColor(Object.keys(acc).length)}80`,
            }),
            stroke: new Stroke({
              color: getColor(Object.keys(acc).length),
              width: 2,
            }),
          });
          acc[cur.key] = { key: cur.key, name: cur.name, style };
        }
        return acc;
      }, {});

    const categories = Object.values(categorizedFeatures);

    features.forEach((f) => {
      const cat = f.get("Category");
      const index = categories.findIndex((c) => c.key === cat);
      if (index !== -1) {
        const foundCat = categories[index];
        if (foundCat) {
          f.setStyle(foundCat.style);
        }
      }
    });

    set({ features, categories });
  };

  const setUrl = (url: string) => {
    const undoInteraction = new UndoRedo();
    updateFeatures([]);
    const vs = new VectorSource<Feature>({ url, format: new GeoJSON() });
    const { vectorSource: ovs } = get();
    if (ovs) {
      ovs.dispose();
    }
    vs.on("featuresloadend", () => {
      const { vectorSource: newVs } = get();
      if (newVs) {
        const fs = newVs.getFeatures();
        updateFeatures(fs);
        //
        undoInteraction.clear();
      }
    });

    const b = new Bar();
    const eb = new EditBar({
      source: vs,
      interactions: {
        // Select: select,
        Delete: false,
        Info: false,
        DrawPoint: false,
        DrawLine: false,
        // DrawPolygon: false,
        DrawRegular: false,
        // ModifySelect: false,
        DrawHole: false,
        // Transform: false,
        Split: false,
        Offset: false,
      } as Interactions,
    });
    b.addControl(eb);

    // redo undo
    b.addControl(
      new Bar({
        group: true,
        controls: [
          new Button({
            html: '<i class="fa fa-undo" ></i>',
            title: "undo...",
            handleClick: () => {
              console.log("undo");
              undoInteraction.undo();
            },
          }),
          new Button({
            html: '<i class="fa fa-repeat" ></i>',
            title: "redo...",
            handleClick: () => {
              undoInteraction.redo();
            },
          }),
        ],
      }),
    );

    // 선택도구 및 수정
    const select = eb.getInteraction("Select") as Select;
    select.on("select", (e) => {
      const selected = e.selected[0];
      set({ selectedFeature: selected });
      const f = e.deselected[0];
      if (f) {
        const { categories } = get();
        const index = categories.findIndex((c) => c.key === f.get("Category"));
        if (index !== -1) {
          const foundCat = categories[index];
          if (foundCat) {
            f.setStyle(foundCat.style);
          }
        }
      }
    });

    // 수정
    const modify = eb.getInteraction("ModifySelect") as unknown as ModifyFeature;
    // 이동 및 크기 조절
    const tf = eb.getInteraction("Transform") as unknown as Transform;
    // 그리기
    const dp = eb.getInteraction("DrawPolygon") as unknown as Draw;
    // 이동 도구에서 선택

    set({ vectorSource: vs, mainBar: b, undoInteraction, select });
  };

  const getFeature = (ol_uid: string) => {
    const { features } = get();
    return features.find((f) => getUid(f) === ol_uid);
  };

  const updateFeature = (feature: Feature) => {
    const { features } = get();
    const idx = features.findIndex((f) => getUid(f) === getUid(feature));
    if (idx === -1) return;
    features[idx] = feature;
    const newFeatures = [...features];
    const v = get().vectorSource;
    if (v) {
      v.clear();
      v.addFeatures(newFeatures);
    }
    updateFeatures(newFeatures);
  };

  const addFeature = (feature: Feature) => {
    const { features } = get();
    const idx = features.findIndex((f) => getUid(f) === getUid(feature));
    if (idx === -1) features.push(feature);
    else {
      features[idx] = feature;
    }
    const newFeatures = [...features];
    const v = get().vectorSource;
    if (v) {
      v.clear();
      v.addFeatures(newFeatures);
    }
    updateFeatures(newFeatures);
  };

  const deleteFeature = (feature: Feature) => {
    const { features } = get();
    const idx = features.findIndex((f) => getUid(f) === getUid(feature));
    if (idx === -1) return;
    features.splice(idx, 1);
    const newFeatures = [...features];
    const v = get().vectorSource;
    if (v) {
      v.clear();
      v.addFeatures(newFeatures);
    }
    updateFeatures(newFeatures);
  };

  return {
    vectorSource,
    setUrl,
    //
    features: [],
    categories: [],
    getFeature,
    addFeature,
    updateFeature,
    deleteFeature,
    //
    mainBar: null,
    undoInteraction: null,
    //
    selectedFeature: null,
    select: null,
  };
});

export default useVectorSourceStore;
