import { Box, Pagination, TextField, Typography } from "@mui/material";
import React from "react";
import { Refresh } from "@mui/icons-material";
import AccordionSummary from "../../../../design/molecules/accordion/AccordionSummary";
import DenseIconButton from "../../../../design/atoms/buttons/DenseIconButton";
import ResourceListItem from "../../../../design/atoms/ResourceListItem";
import TasksList from "./TasksList";
import useExtensionStore from "../../stores/extension.store";
import { Accordion, AccordionDetails } from "../components/accodion.style";
import useProjectsStore from "../../stores/projects.store";
import useTasksStore from "./stores/tasks.store";
import ProjectMoreBtn from "./components/ProjectMoreBtn";
import InnerLoading from "../../../InnerLoading";

export default function TaskContentBox({ height }: { height: number }) {
  const drawer_height = height;
  const { p1, p2, setP2 } = useExtensionStore();

  const { project } = useProjectsStore();
  const { onTasksLoading, page, lastPage, setPage, query, setQuery } = useTasksStore();

  const { fetchTasks } = useTasksStore();
  const handleRefresh = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (project) fetchTasks(project.id);
  };

  const onPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  return (
    <>
      <Accordion expanded={p2} onChange={() => setP2(!p2)}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Box
            display="flex"
            flexGrow={1}
            justifyContent="flex-start"
            alignItems="center"
            gap={"4px"}
          >
            <Typography noWrap sx={{ maxWidth: 200 }}>
              {/* {project ? project.name : "선택된 프로젝트 없음"} */}
              프로젝트
            </Typography>
            {/* <DenseIconButton
              onClick={(e: { stopPropagation: () => void }) => {
                e.stopPropagation();
                setOpenProjectListDialog(true);
              }}
            >
              <OpenProjectIcon />
            </DenseIconButton>
            <DenseIconButton
              onClick={(e: { stopPropagation: () => void }) => {
                e.stopPropagation();
                setOpenProjectDialog(true);
              }}
            >
              <AddProjectIcon />
            </DenseIconButton> */}
            <div style={{ flexGrow: 1 }} />
            <DenseIconButton onClick={handleRefresh}>
              <Refresh />
            </DenseIconButton>
            <ProjectMoreBtn />
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            height: (drawer_height - 8 * 4 * 2) / ((p1 ? 1 : 0) + (p2 ? 1 : 0)),
            gap: "4px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <ResourceListItem>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              {project && (
                <>
                  <Typography>{project.name}</Typography>
                  <Typography variant="caption">{project.description}</Typography>
                </>
              )}
            </Box>
          </ResourceListItem>
          <Box
            sx={{
              background: "white",
              padding: 1,
              width: "100%",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <TextField
              sx={{ width: "100%" }}
              variant="filled"
              label="작업 찾기"
              size="small"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                setPage(1);
              }}
            />
          </Box>
          <Box
            sx={{
              position: "relative",
              gap: "4px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <TasksList />
            <InnerLoading loading={onTasksLoading} />
          </Box>

          <Box sx={{ flex: "none", flexGrow: 1, alignSelf: "stretch" }}> </Box>
          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              paddingY: 1,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          >
            <Pagination count={lastPage} page={page} onChange={onPageChange} />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
