import { persist } from "zustand/middleware";
import { create } from "zustand";
import MainApiClient from "../../../../../clients/MainApiClient";
import { TaskTypeEntity } from "../../../../../clients/MainApiClient/dtos/entities";

const client = new MainApiClient();

interface TaskTypeStore {
  taskTypes: TaskTypeEntity[];
  setTaskTypes: (newTaskTypes: TaskTypeEntity[]) => void;

  page: number;
  setPage: (page: number) => void;
  lastPage: number;
  setLastPage: (page: number) => void;
  onTaskTypesLoading: boolean;
  setOnTaskTypesLoading: (loading: boolean) => void;
  fetchTaskTypes: () => Promise<void>;
  //
  taskType: TaskTypeEntity | null;
  setTaskType: (taskType: TaskTypeEntity) => void;
}

const useTaskTypesStore = create<TaskTypeStore>()(
  persist(
    (set, get) => ({
      taskTypes: [],
      setTaskTypes: (newTaskTypes: TaskTypeEntity[]) => set({ taskTypes: newTaskTypes }),
      page: 1,
      setPage: (page: number) => {
        set({ page });
        get().fetchTaskTypes();
      },
      lastPage: 1,
      setLastPage: (lastPage: number) => set({ lastPage }),
      onTaskTypesLoading: false,
      setOnTaskTypesLoading: (loading: boolean) => set({ onTaskTypesLoading: loading }),
      fetchTaskTypes: () => {
        set({ onTaskTypesLoading: true });
        const { page } = get();

        return client
          .readTaskTypes({ page, per_page: 10 })
          .then(({ types, last_page }) => {
            set({ taskTypes: types });
            set({ lastPage: last_page });
          })
          .finally(() => {
            set({ onTaskTypesLoading: false });
          });
      },
      taskType: null,
      setTaskType: (taskType: TaskTypeEntity) => set({ taskType }),
    }),
    { name: "task-types-Store" },
  ),
);

export default useTaskTypesStore;
