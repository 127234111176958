export const primary = "#24326B";
export const negative = "#8B0000";
export const panel_background_color = "#ECECEC";
// export const panel_background_color = "transparent";

export const tab_unselected_color = "#2F382D";
export const tab_unselected_bg_color = "#C8C8C8";
export const tab_unselected_hover_bg_color = "#989898";

const colors = {
  primary,
  panel_background_color,
};

export default colors;
