import { styled } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
  backgroundColor: "transparent",
}));

const SHOW_SCROLLBAR = /Mobi|Android/i.test(navigator.userAgent);
const AccordionDetails = styled(MuiAccordionDetails)(
  // (({ theme }) => ({
  () => ({
    padding: 0,
    overflowY: "scroll",
    scrollbarWidth: SHOW_SCROLLBAR ? "auto" : "none", // Firefox,
    "&::-webkit-scrollbar": {
      display: SHOW_SCROLLBAR ? "auto" : "none", // Chrome, Safari, Opera 수정
    },
  }),
);

export { Accordion, AccordionDetails, SHOW_SCROLLBAR };
