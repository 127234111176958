import { MoreHoriz, Refresh } from "@mui/icons-material";
import { Divider, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import DenseIconButton from "../../../../../design/atoms/buttons/DenseIconButton";
import useTasksStore from "../stores/tasks.store";
import useProjectsStore from "../../../stores/projects.store";
import CreateTaskDialog from "../CreateTaskDialog";
import { AddProjectIcon, AddTaskIcon, OpenProjectIcon } from "../../../../../design/atoms/icons";
import StyledMenu from "../../../../StyledMenu";
import CreateProjectDialog from "../../../components/CreateProjectDialog";
import ProjectListDialog from "../../../components/ProjectListDialog";

export default function ProjectMoreBtn() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { project } = useProjectsStore();
  const { fetchTasks } = useTasksStore();

  // 작업 생성 모달
  const [openTaskDialog, setOpenTaskDialog] = React.useState(false);
  // 프로젝트 리스트 모달
  const [openProjectListDialog, setOpenProjectListDialog] = React.useState(false);
  // 프로젝트 생성 모달
  const [openProjectDialog, setOpenProjectDialog] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    event.preventDefault();
    event.stopPropagation();
  };
  const handleClose = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleAddTask = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
    setOpenTaskDialog(true);
  };

  const handOpenProjectList = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
    setOpenProjectListDialog(true);
  };

  const handleCreateProject = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
    setOpenProjectDialog(true);
  };

  return (
    <DenseIconButton onClick={(event) => handleClick(event)}>
      <MoreHoriz />
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {/* <MenuItem>
          <Typography>프로젝트</Typography>
        </MenuItem> */}
        <MenuItem onClick={handOpenProjectList}>
          <OpenProjectIcon />
          프로젝트 열기
        </MenuItem>
        <MenuItem onClick={handleCreateProject}>
          <AddProjectIcon />
          프로젝트 추가
        </MenuItem>
        {/* {project && (
          <>
            <MenuItem onClick={handleRefresh}>
              <Refresh />
              새로고침
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
          </>
        )} */}
        <MenuItem onClick={handleAddTask}>
          <AddTaskIcon />
          태스크 추가
        </MenuItem>
      </StyledMenu>
      <CreateTaskDialog open={openTaskDialog} setOpen={setOpenTaskDialog} />
      <CreateProjectDialog open={openProjectDialog} setOpen={setOpenProjectDialog} />
      <ProjectListDialog open={openProjectListDialog} setOpen={setOpenProjectListDialog} />
    </DenseIconButton>
  );
}
