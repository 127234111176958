import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import useSwipeCommonStore, { MODE } from "../../stores/useSwipeCommon.store";

export default function ModeToggleBtns() {
  const { mode, setMode } = useSwipeCommonStore();

  return (
    <ToggleButtonGroup
      exclusive
      value={mode}
      style={{
        position: "absolute",
        top: "0",
        right: "0",
        margin: "10px",
        background: "white",
      }}
      onChange={(e: React.MouseEvent<HTMLElement>, v: string) => {
        if (v === null) return;
        // setCMode(v as MODE);
        setMode(v as MODE);
      }}
    >
      <ToggleButton value="compare">
        <i className="fg-screen-dub"></i>
      </ToggleButton>
      <ToggleButton value="swipev">
        <i className="fg-screen-split-h"></i>
      </ToggleButton>
      <ToggleButton value="swipeh">
        <i className="fg-screen-split-v"></i>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
