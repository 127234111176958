import { IconButton as MuiIconButton, IconButtonProps, styled } from "@mui/material";
import { dense_icon_button_height, dense_icon_button_width } from "../../tokens/spacing";

const DenseIconButton = styled((props: IconButtonProps) => (
  <MuiIconButton centerRipple={false} {...props} color={props.color || "primary"} />
  // ))(({theme})=>({
))(() => ({
  width: dense_icon_button_width,
  height: dense_icon_button_height,
  // 사각형으로
  borderRadius: 0,
  "& .MuiTouchRipple-root": {
    borderRadius: 0,
  },
  "& .MuiTouchRipple-ripple": {
    borderRadius: 0,
  },
}));

export default DenseIconButton;
