import { Feature } from "ol";
import VectorSource from "ol/source/Vector";
import { create } from "zustand";
import TileLayer from "ol/layer/Tile";
import TileSource from "ol/source/Tile";
import VectorLayer from "ol/layer/Vector";
import { XYZ } from "ol/source";
import { TaskEntity } from "../../../clients/MainApiClient/dtos/entities";

interface SwipeLayerState {
  tileLayer1: TileLayer<TileSource>;
  tileLayer2_1: TileLayer<TileSource>;
  tileLayer2_2: TileLayer<TileSource>;
  vectorLayer: VectorLayer<Feature>;

  // // url 설정
  setSource: (source: VectorSource) => void;
  //
  task: TaskEntity | null;
  setTask: (task: TaskEntity) => void;
}

const useSwipeLayerStore = create<SwipeLayerState>()((set, get) => {
  return {
    tileLayer1: new TileLayer({}),
    tileLayer2_1: new TileLayer({}),
    tileLayer2_2: new TileLayer({}),
    //
    vectorLayer: new VectorLayer<Feature>(),
    setSource: (source: VectorSource) => {
      set({ vectorLayer: new VectorLayer<Feature>({ source }) });
    },

    //
    task: null,
    setTask: (task: TaskEntity) => {
      set({ task });
      const { resource: resource1 } = task.inputs[0];
      const { resource: resource2 } = task.inputs[1];

      const t1 = new TileLayer({
        source: new XYZ({
          url: `/api/resources/${resource1.id}/tiles/{z}/{x}/{-y}`,
          maxZoom: 19,
        }),
      });
      const t2_1 = new TileLayer({
        source: new XYZ({
          url: `/api/resources/${resource1.id}/tiles/{z}/{x}/{-y}`,
          maxZoom: 19,
        }),
      });
      const t2_2 = new TileLayer({
        source: new XYZ({
          url: `/api/resources/${resource2.id}/tiles/{z}/{x}/{-y}`,
          maxZoom: 19,
        }),
      });

      set({ tileLayer1: t1, tileLayer2_1: t2_1, tileLayer2_2: t2_2 });
    },
  };
});

export default useSwipeLayerStore;
