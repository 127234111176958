import React from "react";
import { Box, BoxProps, CircularProgress } from "@mui/material";

export default function InnerLoading(props: BoxProps & { loading: boolean }) {
  const { loading, ...restProps } = props;
  return (
    <Box
      {...restProps}
      sx={{
        ...{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: loading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999,
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
        ...restProps.sx,
      }}
    >
      <CircularProgress />
    </Box>
  );
}
