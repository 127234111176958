import { MoreHoriz } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import React from "react";
import DenseIconButton from "../../../../../design/atoms/buttons/DenseIconButton";
import { ResourceEntity } from "../../../../../clients/MainApiClient/dtos/entities";
import useResourcesStore from "../stores/resource.store";

export default function MoreBtn({ resource }: { resource: ResourceEntity }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log("handleClick");
    setAnchorEl(event.currentTarget);

    event.preventDefault();
    event.stopPropagation();
  };
  const handleClose = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const { deleteResource } = useResourcesStore();

  const handleDelete = (event: React.MouseEvent<HTMLLIElement>) => {
    deleteResource(resource);
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <DenseIconButton id={`menu-button-${resource.id}`} onClick={(event) => handleClick(event)}>
      <MoreHoriz />
      <Menu
        id={`menu-button-${resource.id}`}
        anchorEl={anchorEl}
        open={open}
        MenuListProps={{
          "aria-labelledby": `menu-button-${resource.id}`,
        }}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>삭제</MenuItem>
      </Menu>
    </DenseIconButton>
  );
}
