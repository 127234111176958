import { MoreHoriz } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { ProjectEntity } from "../../../clients/MainApiClient/dtos/entities";
import useProjectsStore from "../stores/projects.store";
import DenseIconButton from "../../../design/atoms/buttons/DenseIconButton";

export default function MoreBtn({ project }: { project: ProjectEntity }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    event.preventDefault();
    event.stopPropagation();
  };
  const handleClose = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const { deleteProject } = useProjectsStore();

  const handleDelete = (event: React.MouseEvent<HTMLLIElement>) => {
    deleteProject(project);
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <DenseIconButton id={`menu-button-${project.id}`} onClick={(event) => handleClick(event)}>
      <MoreHoriz />
      <Menu
        id={`menu-button-${project.id}`}
        anchorEl={anchorEl}
        open={open}
        MenuListProps={{
          "aria-labelledby": `menu-button-${project.id}`,
        }}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>삭제</MenuItem>
      </Menu>
    </DenseIconButton>
  );
}
