import { create } from "zustand";
import { persist } from "zustand/middleware";
import { ResourceEntity } from "../../../../../clients/MainApiClient/dtos/entities";
import MainApiClient from "../../../../../clients/MainApiClient";

const client = new MainApiClient();

interface ResourcesStore {
  resources: ResourceEntity[];
  setResources: (newResources: ResourceEntity[]) => void;
  page: number;
  setPage: (page: number) => void;
  lastPage: number;
  setLastPage: (page: number) => void;
  onResourceLoading: boolean;
  setOnResourceLoading: (loading: boolean) => void;
  //
  query: string;
  setQuery: (query: string) => void;
  fetchResources: () => Promise<void>;
  //
  deleteResource(resource: ResourceEntity): Promise<void>;
}

const useResourcesStore = create<ResourcesStore>()(
  persist(
    (set, get) => ({
      resources: [],
      setResources: (newResources: ResourceEntity[]) => set({ resources: newResources }),
      page: 1,
      setPage: (page: number) => {
        set({ page });
        get().fetchResources();
      },
      lastPage: 1,
      setLastPage: (lastPage: number) => set({ lastPage }),
      query: "",
      setQuery: (query: string) => set({ query }),
      fetchResources: () => {
        set({ onResourceLoading: true });
        const { page, query } = get();
        return client
          .readResources({ q: query, page, per_page: 5 })
          .then(({ resources, last_page }) => {
            set({ resources });
            set({ lastPage: last_page });
          })
          .finally(() => {
            set({ onResourceLoading: false });
          });
      },
      onResourceLoading: false,
      setOnResourceLoading: (loading: boolean) => set({ onResourceLoading: loading }),
      deleteResource: (resource: ResourceEntity) => {
        set({ onResourceLoading: true });
        return client
          .deleteResource(resource)
          .then(() => {
            get().fetchResources();
            set({ resources: get().resources.filter((t) => t.id !== resource.id) });
          })
          .finally(() => {
            set({ onResourceLoading: false });
            get().fetchResources();
          });
      },
    }),
    {
      name: "resource-page-store",
      partialize: (state) => ({ page: state.page, lastPage: state.lastPage }),
    },
  ),
);

export default useResourcesStore;
