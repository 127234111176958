import { create } from "zustand";
import { persist } from "zustand/middleware";
import MainApiClient from "../../../../../clients/MainApiClient";
import { TaskEntity } from "../../../../../clients/MainApiClient/dtos/entities";

const client = new MainApiClient();

interface TasksStore {
  tasks: TaskEntity[];
  setTasks: (newTasks: TaskEntity[]) => void;
  projectId: number | null;
  setProjectId: (projectId: number) => void;
  page: number;
  setPage: (page: number) => void;
  lastPage: number;
  setLastPage: (page: number) => void;
  query: string;
  setQuery: (query: string) => void;
  onTasksLoading: boolean;
  setOnTasksLoading: (loading: boolean) => void;
  fetchTasks: (projectId: number) => Promise<void>;
  task: TaskEntity | null;
  setTask: (task: TaskEntity | null) => void;
  deleteTask(task: TaskEntity): Promise<void>;
}

const useTasksStore = create<TasksStore>()(
  persist(
    (set, get) => ({
      projectId: null,
      setProjectId: (projectId: number) => {
        set({ projectId });
        get().fetchTasks(projectId);
      },
      tasks: [],
      setTasks: (newTasks: TaskEntity[]) => set({ tasks: newTasks }),
      page: 1,
      setPage: (page: number) => {
        set({ page });
        const { projectId } = get();
        if (projectId) get().fetchTasks(projectId);
      },
      lastPage: 1,
      setLastPage: (lastPage: number) => set({ lastPage }),
      fetchTasks: (projectId: number) => {
        set({ onTasksLoading: true });
        set({ projectId });
        const { page } = get();

        return client
          .readTasks({ project_id: projectId, page, per_page: 10, q: get().query })
          .then(({ tasks, last_page }) => {
            set({ tasks });
            set({ lastPage: last_page });
          })
          .finally(() => {
            set({ onTasksLoading: false });
          });
      },
      onTasksLoading: false,
      setOnTasksLoading: (loading: boolean) => set({ onTasksLoading: loading }),
      task: null,
      setTask: (task: TaskEntity | null) => set({ task }),
      deleteTask: (task: TaskEntity) => {
        set({ onTasksLoading: true });
        return client
          .deleteTask({ taskId: task.id, projectId: task.project_id })
          .then(() => {
            set({ tasks: get().tasks.filter((t) => t.id !== task.id) });
          })
          .finally(() => {
            set({ onTasksLoading: false });
            const { projectId } = get();
            if (projectId) get().fetchTasks(projectId);
          });
      },
      query: "",
      setQuery: (query: string) => set({ query }),
    }),
    {
      name: "tasks-page-store",
      partialize: (state) => ({ page: state.page, lastPage: state.lastPage }),
    },
  ),
);

export default useTasksStore;
