import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { Style } from "ol/style";
import useVectorSourceStore from "../../../stores/useVectorSource.store";
import useConfidenceStore from "./confidence.store";

const categoryBase = [
  { key: "1", name: "신축" },
  { key: "2", name: "철거" },
  { key: "3", name: "갱신" },
  { key: "4", name: "색변화" },
];
const initCategoryBoardItems = [
  {
    key: "1",
    name: "신축",
    count: 0,
    showCount: 0,
    checked: true,
    disabled: false,
  },
  {
    key: "2",
    name: "철거",
    count: 0,
    showCount: 0,
    checked: true,
    disabled: false,
  },
  {
    key: "3",
    name: "갱신",
    count: 0,
    showCount: 0,
    checked: true,
    disabled: false,
  },
  {
    key: "4",
    name: "색변화",
    count: 0,
    showCount: 0,
    checked: true,
    disabled: false,
  },
];
interface CategoryBoardItem {
  key: string;
  name: string;
  count: number;
  showCount: number;
  checked: boolean;
  disabled?: boolean;
}

export default function CategoryAccordion() {
  const [categoryBoardItems, setCategoryBoardItems] = useState<CategoryBoardItem[]>([]);
  const { features, categories } = useVectorSourceStore();
  const { conf } = useConfidenceStore();

  // accordion 초기화 (보이기, 숨긴 갯수, 체크박스)
  useEffect(() => {
    const items = JSON.parse(JSON.stringify(initCategoryBoardItems)) as CategoryBoardItem[];
    features.reduce((acc, cur) => {
      const category = cur.get("Category");
      const item = items.find((cbi) => cbi.key === category);
      if (item) {
        item.count += 1;
        if (conf[0] <= cur.get("confidence") && cur.get("confidence") <= conf[1]) {
          item.showCount += 1;
        }
      }
      return acc;
    }, 0);
    setCategoryBoardItems(items);
  }, [conf, features]);

  // 조건에 따라 보이기 안보이기
  useEffect(() => {
    features.forEach((f) => {
      const category = f.get("Category");
      const item = categoryBoardItems.find((cbi) => cbi.key === category);
      // 우선 모든 숨기기
      f.setStyle(new Style({}));
      // 체크박스 체크되어 있고, 신뢰도 범위 안에 있으면 보이기
      if (
        item &&
        item.checked &&
        conf[0] <= f.get("confidence") &&
        f.get("confidence") <= conf[1]
      ) {
        const foundCat = categories.find((c) => c.key === category)!;
        f.setStyle(foundCat?.style);
      }
    });
  }, [categories, categoryBoardItems, conf, features]);

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          클래스
        </AccordionSummary>
        <AccordionDetails>
          {categoryBase.map((cb) => {
            let item = categoryBoardItems.find((cbi) => cbi.key === cb.key)!;
            const cat = categories.find((c) => c.key === cb.key)!;
            let color = cat?.style.getStroke()?.getColor() as string;
            if (!color) color = "#000000";
            if (!item)
              item = {
                name: "-",
                key: cb.key,
                count: 0,
                showCount: 0,
                checked: false,
                disabled: true,
              } as CategoryBoardItem;
            return (
              <Box key={item.key}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "16px",
                      height: "16px",
                      backgroundColor: color,
                      marginLeft: "8px",
                      marginRight: "8px",
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={item.disabled}
                        checked={item.checked}
                        onChange={(e) => {
                          setCategoryBoardItems((prev) =>
                            prev.map((c) => {
                              if (c.key === item.key) {
                                return { ...c, checked: e.target.checked };
                              }
                              return c;
                            }),
                          );
                        }}
                      />
                    }
                    label={`${item.name}( ${item.showCount}/${item.count})`}
                  />
                </Box>
              </Box>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
