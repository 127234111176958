import { Backdrop, Box, CircularProgress, LinearProgress, Typography } from "@mui/material";
import React from "react";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import UploadResourceDialog from "./UploadResourceDialog";
import TiffResourceList from "./TiffResourceList";
import useExtensionStore from "../../stores/extension.store";
import AccordionSummary from "../../../../design/molecules/accordion/AccordionSummary";
import DenseIconButton from "../../../../design/atoms/buttons/DenseIconButton";
import { UploadIcon } from "../../../../design/atoms/icons";
import { Accordion, AccordionDetails } from "../components/accodion.style";
import useResourcesStore from "./stores/resource.store";
import InnerLoading from "../../../InnerLoading";

export default function ResourceContentBox({ height }: { height: number }) {
  // 리소스 업로드 모달
  const [openResourceDialog, setOpenResourceDialog] = React.useState(false);
  const drawer_height = height;
  const { p1, setP1, p2 } = useExtensionStore();
  const { fetchResources } = useResourcesStore();
  const { onResourceLoading } = useResourcesStore();
  return (
    <>
      <Accordion
        expanded={p1}
        sx={{ backgroundColor: "transparent", border: "0px" }}
        onChange={() => setP1(!p1)}
      >
        <AccordionSummary>
          <Box
            display="flex"
            flexGrow={1}
            justifyContent="flex-start"
            alignItems="center"
            gap={"4px"}
          >
            <Typography alignSelf="center">리소스</Typography>
            <div style={{ flexGrow: 1 }} />
            <DenseIconButton
              onClick={(e: { stopPropagation: () => void }) => {
                e.stopPropagation();
                setOpenResourceDialog(true);
              }}
            >
              <UploadIcon />
            </DenseIconButton>
            <DenseIconButton
              onClick={(e: { stopPropagation: () => void }) => {
                e.stopPropagation();
                fetchResources();
                // setOnResourceLoading(true);
              }}
            >
              <RefreshIcon />
            </DenseIconButton>
          </Box>
        </AccordionSummary>

        <AccordionDetails
          sx={{
            height: (drawer_height - 8 * 4 * 2) / ((p1 ? 1 : 0) + (p2 ? 1 : 0)),
          }}
        >
          <Box
            sx={{
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: 0,
              gap: "4px",
              // borderBottom: '4px solid #24326B',
              flex: "none",
              order: 1,
              flexGrow: 1,
              height: "100%",
            }}
          >
            {/* <Box
              sx={{
                position: "sticky",
                top: 0,
                left: 0,
                width: "100%",
                zIndex: 1,
              }}
            ></Box> */}

            <TiffResourceList />
          </Box>
        </AccordionDetails>
      </Accordion>
      <UploadResourceDialog open={openResourceDialog} setOpen={setOpenResourceDialog} />
    </>
  );
}
