import { create } from "zustand";
import { persist } from "zustand/middleware";

interface OrthophotoMapState {
  base_map_url: string;
  ortho_map_url: string;
  center: [number, number];
  zoom: number;
  minZoom: number;
  maxZoom: number;
  extent: [number, number, number, number];
  setBaseMapUrl: (url: string) => void;
  setOrthoMapUrl: (url: string) => void;
  setCenter: (center: [number, number]) => void;
  setZoom: (zoom: number) => void;
  setMaxZoom: (maxZoom: number) => void;
  setMinZoom: (minZoom: number) => void;
  setExtent: (extent: [number, number, number, number]) => void;
}

const useOrthophotoMapStore = create<OrthophotoMapState>()(
  persist(
    (set) => ({
      base_map_url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
      ortho_map_url: "/tiles/nowon-2022/{z}/{x}/{-y}.png",
      center: [139.7392358, 35.652832],
      zoom: 14,
      minZoom: 1,
      maxZoom: 20,
      extent: [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244],
      setBaseMapUrl: (url: string) => set({ base_map_url: url }),
      setOrthoMapUrl: (url: string) => set({ ortho_map_url: url }),
      setCenter: (center: [number, number]) => set({ center }),
      setZoom: (zoom: number) => set({ zoom }),
      setMaxZoom: (maxZoom: number) => set({ maxZoom }),
      setMinZoom: (minZoom: number) => set({ minZoom }),
      setExtent: (extent: [number, number, number, number]) => set({ extent }),
    }),
    {
      name: "orthophoto-map-storage", // unique name
    },
  ),
);

export default useOrthophotoMapStore;
