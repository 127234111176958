export const b = 16; // ?
export const panel_padding_x = 0.5 * b;

export const xs = 0.25 * b;
export const sm = 0.6 * b;
export const md = 1 * b;
export const xl = 3 * b;
export const xxl = 6 * b;

// export const dense_icon_button_width = 2*b;
// export const dense_icon_button_height = 2*b;
export const dense_icon_button_width = 2 * b;
export const dense_icon_button_height = 2 * b;

export const panel_header_height = 2 * b;
