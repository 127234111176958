import { CreateTaskDto } from "./create-taks";
import { TaskEntity } from "./dtos/entities";
import {
  ReqCreateProjectDto,
  // responses
  ResCreateProjectResultDto,
  ResDeleteTaskResultDto,
  ResReadProjectsResultDto,
  ResReadResourcesResultDto,
  ResReadTasksResultDto,
  ResDeleteResourceResultDto,
  ResDeleteProjectResultDto,
  ResCreateResourceResultDto,
  ResReadTaskTypesResultDto,
  ReqCreateTaskResultDto,
} from "./dtos/index";

export default class MainApiClient {
  private baseUrl: string;

  // constructor(baseUrl: string) {
  //   this.baseUrl = baseUrl;
  // }

  constructor() {
    this.baseUrl = "/api";
  }

  async createProject(p: ReqCreateProjectDto): Promise<ResCreateProjectResultDto> {
    const { name, description } = p;

    const response = await fetch(`${this.baseUrl}/projects`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, description }),
    });

    if (!response.ok) {
      throw new Error("Failed to create project");
    }

    const { code, result } = (await response.json()) as {
      code: number;
      result: ResCreateProjectResultDto;
    };

    if (code !== 201000) {
      throw new Error("Failed to get project list");
    }

    return result;
  }

  async readProjectList(p: {
    q?: string;
    page?: number;
    per_page?: number;
  }): Promise<ResReadProjectsResultDto> {
    const { page = 1, per_page = 15 } = p;
    const response = await fetch(
      `${this.baseUrl}/projects?page=${page}&per_page=${per_page}&q=${p.q}`,
    );
    if (!response.ok) {
      throw new Error("Failed to get project list");
    }

    const { code, result } = (await response.json()) as {
      code: number;
      result: ResReadProjectsResultDto;
    };
    if (code !== 200000) {
      throw new Error("Failed to get project list");
    }

    return result;
  }

  async deleteProject(project: { id: number }): Promise<ResDeleteProjectResultDto> {
    const response = await fetch(`${this.baseUrl}/projects/${project.id}`, {
      method: "DELETE",
    });
    if (!response.ok) {
      throw new Error("Failed to delete project");
    }

    const { code, result } = (await response.json()) as {
      code: number;
      result: ResDeleteProjectResultDto;
    };
    if (code !== 200000) {
      throw new Error("Failed to get resource list");
    }

    return result;
  }

  async createResource(p: { file: File; name: string }): Promise<ResCreateResourceResultDto> {
    const { file, name } = p;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);

    const response = await fetch(`${this.baseUrl}/resources`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to create resource");
    }

    const { code, result } = (await response.json()) as {
      code: number;
      result: ResCreateResourceResultDto;
    };
    if (code !== 201000) {
      throw new Error("Failed to create resource");
    }

    return result;
  }

  async readResources(p: {
    q?: string;
    page?: number;
    per_page?: number;
  }): Promise<ResReadResourcesResultDto> {
    const { page = 1, per_page = 15, q } = p;
    const base = `${this.baseUrl}/resources?page=${page}&per_page=${per_page}`;
    const url = q ? `${base}&q=${q}` : base;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to get resource list");
    }

    const { code, result } = (await response.json()) as {
      code: number;
      result: ResReadResourcesResultDto;
    };
    if (code !== 200000) {
      throw new Error("Failed to get resource list");
    }

    return result;
  }

  async readTasks(p: {
    q?: string;
    project_id: number;
    page?: number;
    per_page?: number;
  }): Promise<ResReadTasksResultDto> {
    const { project_id, page = 1, per_page = 15 } = p;
    const response = await fetch(
      `${this.baseUrl}/projects/${project_id}/tasks?page=${page}&per_page=${per_page}&q=${p.q}`,
    );
    if (!response.ok) {
      throw new Error("Failed to get task list");
    }

    const { code, result } = (await response.json()) as {
      code: number;
      result: ResReadTasksResultDto;
    };
    if (code !== 200000) {
      throw new Error("Failed to get task list");
    }

    return result;
  }

  async deleteTask(p: { taskId: number; projectId: number }): Promise<ResDeleteTaskResultDto> {
    const { taskId, projectId } = p;
    const response = await fetch(`${this.baseUrl}/projects/${projectId}/tasks/${taskId}`, {
      method: "DELETE",
    });
    if (!response.ok) {
      throw new Error("Failed to get task list");
    }

    const { code, result } = (await response.json()) as {
      code: number;
      result: {
        code: number;
        task: TaskEntity;
      };
    };
    if (code !== 200000) {
      throw new Error("Failed to get task list");
    }

    return result;
  }

  async deleteResource(resource: { id: number }): Promise<ResDeleteResourceResultDto> {
    const response = await fetch(`${this.baseUrl}/resources/${resource.id}`, {
      method: "DELETE",
    });
    if (!response.ok) {
      throw new Error("Failed to delete resource");
    }

    const { code, result } = (await response.json()) as {
      code: number;
      result: ResDeleteResourceResultDto;
    };
    if (code !== 200000) {
      throw new Error("Failed to get task list");
    }

    return result;
  }

  async readTaskTypes(p: { page?: number; per_page?: number }): Promise<ResReadTaskTypesResultDto> {
    const { page = 1, per_page = 15 } = p;
    const response = await fetch(`${this.baseUrl}/task-types?page=${page}&per_page=${per_page}`);
    if (!response.ok) {
      throw new Error("Failed to get task types");
    }

    const { code, result } = (await response.json()) as {
      code: number;
      result: ResReadTaskTypesResultDto;
    };
    if (code !== 200000) {
      throw new Error("Failed to get task list");
    }

    return result;
  }

  async createTask(p: CreateTaskDto): Promise<ReqCreateTaskResultDto> {
    const { project_id, ...payload } = p;
    const response = await fetch(`${this.baseUrl}/projects/${project_id}/tasks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("Failed to create task");
    }

    const { code, result } = (await response.json()) as {
      code: number;
      result: ReqCreateTaskResultDto;
    };
    if (code !== 201000) {
      throw new Error("Failed to create task");
    }

    return result;
  }
}
