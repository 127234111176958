import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Box, DialogTitle, MenuItem, Select, TextField, Typography } from "@mui/material";
import useTaskTypesStore from "./stores/task-type.store";
import { ResourceEntity, TaskTypeEntity } from "../../../../clients/MainApiClient/dtos/entities";
import ResourceSearchInput from "./components/ResourceSearchInput";
import MainApiClient from "../../../../clients/MainApiClient";
import useProjectsStore from "../../stores/projects.store";
import useTasksStore from "./stores/tasks.store";
import InnerLoading from "../../../InnerLoading";

export default function CreateTaskDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (o: boolean) => void;
}) {
  const [taskType, setTaskType] = React.useState<TaskTypeEntity | null>(null);
  const [taskName, setTaskName] = React.useState("");
  const [taskDescription, setTaskDescription] = React.useState("");
  const [inputs, setInputs] = React.useState<Record<number, ResourceEntity>>({});

  const { taskTypes, fetchTaskTypes } = useTaskTypesStore();

  React.useEffect(() => {
    fetchTaskTypes();
  }, [fetchTaskTypes]);

  const preventAndStopPropagation = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // ----
  const { fetchTasks } = useTasksStore();
  const client = React.useMemo(() => {
    return new MainApiClient();
  }, []);

  const { project } = useProjectsStore();

  const [onCreateTask, setOnCreateTask] = React.useState(false);

  const reqCreateTask = () => {
    if (taskType === null) return;
    if (project === null) return;

    const payload = {
      project_id: project.id,
      name: taskName,
      description: taskDescription,
      type_id: taskType.id,
      inputs: Object.keys(inputs).map((order) => {
        return {
          order: Number(order),
          resource_id: inputs[Number(order)].id,
        };
      }),
    };

    setOnCreateTask(true);
    client
      .createTask(payload)
      .then(() => {
        fetchTasks(project.id);
        // 초기화
        setTaskName("");
        setTaskDescription("");
        setTaskType(null);
        setInputs({});
        setOpen(false);
      })
      .finally(() => {
        setOnCreateTask(false);
      });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {
          setOpen(false);
        }}
        onClick={(e) => {
          preventAndStopPropagation(e);
        }}
      >
        <DialogTitle id="alert-dialog-title">작업 생성</DialogTitle>
        <DialogContent
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              size="small"
              variant="filled"
              label="작업 이름"
              value={taskName}
              onClick={(e) => {
                preventAndStopPropagation(e);
              }}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setTaskName(e.target.value);
              }}
            />
            <TextField
              size="small"
              variant="filled"
              label="작업 설명"
              value={taskDescription}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setTaskDescription(e.target.value);
              }}
            />
            <Select
              size="small"
              label="작업 종류"
              value={taskType ? taskType.id : ""}
              onChange={(e) => {
                const type_id = Number(e.target.value);
                const type = taskTypes.find((t) => t.id === type_id);
                if (type) setTaskType(type);
              }}
            >
              {taskTypes.map((t) => (
                <MenuItem
                  key={t.id}
                  value={t.id}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setTaskType(t);
                  }}
                >
                  {t.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {taskType && (
            // inputs
            <>
              <Box display="flex" flexDirection="column" gap={2} sx={{ paddingTop: 2 }}>
                {taskType.inputs
                  .sort((a, b) => a.order - b.order)
                  .map((input) => {
                    return (
                      <Box key={input.order}>
                        <Box>
                          <Typography>{input.name}</Typography>
                          <Typography variant="caption">{input.description}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" gap={2}>
                          <ResourceSearchInput
                            sx={{ width: "100%" }}
                            onResourceChanged={(r) => {
                              if (r) {
                                setInputs((prev) => ({ ...prev, [input.order]: r }));
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
              {/* {Object.keys(inputs).length > 0 && (
                <Box display="flex" flexDirection="column" gap={2} sx={{ paddingTop: 2 }}>
                  {Object.entries(inputs).map(([order, resource]) => (
                    <Box key={order}>
                      <Typography>Order: {order}</Typography>
                      <Typography>Name: {resource.name}</Typography>
                      <Typography>File Name: {resource.file_name}</Typography>
                    </Box>
                  ))}
                </Box>
              )} */}
              <br />
              <Box display="flex" flexDirection="column" gap={2}>
                {taskType.outputs
                  .sort((a, b) => a.order - b.order)
                  .map((output) => {
                    return (
                      <Box key={output.order}>
                        <Typography>{output.name}</Typography>
                        <Typography variant="caption">{output.description}</Typography>
                      </Box>
                    );
                  })}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ backgroundColor: "transparent" }}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(false);
            }}
          >
            취소
          </Button>
          <Button
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              reqCreateTask();
            }}
          >
            생성
          </Button>
        </DialogActions>
        <InnerLoading loading={onCreateTask} />
      </Dialog>
    </React.Fragment>
  );
}
