import ResourceContentBox from "./resource";
import { panel_background_color } from "../../../design/tokens/colors";
import TaskContentBox from "./task";

export default function DrawerContent({ height, width }: { height: number; width: number }) {
  const drawer_height = height;

  return (
    <div
      style={{
        width,
        height: drawer_height,
        backgroundColor: panel_background_color,
      }}
    >
      <ResourceContentBox height={drawer_height} />
      <TaskContentBox height={drawer_height} />
    </div>
  );
}
