import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import useSwipeLayerStore from "../../stores/useSwipeLayer.store";
import useSwipeCommonStore from "../../stores/useSwipeCommon.store";

export default function TileLayer2FormControlLabel({ tile }: { tile: string }) {
  const [checked, setChecked] = useState(true);
  const { tileLayer1, tileLayer2_1 } = useSwipeLayerStore();
  const { mode } = useSwipeCommonStore();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    tileLayer1.setVisible(true);
    switch (mode) {
      case "compare":
        tileLayer1.setVisible(checked);
        // tileLayer2_1.setVisible(checked);
        break;
      case "swipev":
      case "swipeh":
        // tileLayer2_1.setVisible(checked);
        tileLayer2_1.setVisible(checked);
        break;
      default:
        break;
    }
  }, [mode, checked, tileLayer1, tileLayer2_1]);

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} />}
      label={tile}
    />
  );
}
