import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, IconButton, Pagination, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import { Refresh } from "@mui/icons-material";
import useProjectsStore from "../stores/projects.store";
import DenseIconButton from "../../../design/atoms/buttons/DenseIconButton";
import { OpenProjectIcon } from "../../../design/atoms/icons";
import MoreBtn from "./MoreBtn";
import InnerLoading from "../../InnerLoading";

export default function ProjectListDialog(p: { open: boolean; setOpen: (open: boolean) => void }) {
  const { open, setOpen } = p;

  const {
    page,
    setPage,
    projects,
    lastPage: totalPage,
    onProjectsLoading,
    setProject,
    fetchProjects,
    query,
    setQuery,
  } = useProjectsStore();

  const onPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  React.useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line
  }, []);
  const preventAndStopPropagation = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        scroll="paper"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {
          setOpen(false);
        }}
        onClick={(e) => {
          preventAndStopPropagation(e);
        }}
      >
        <DialogTitle>프로젝트 리스트</DialogTitle>
        <Box
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
          }}
        >
          <IconButton
            onClick={(e) => {
              fetchProjects();
              preventAndStopPropagation(e);
            }}
          >
            <Refresh />
          </IconButton>
          <IconButton
            onClick={(e) => {
              setOpen(false);
              preventAndStopPropagation(e);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ height: 3, backgroundColor: grey[200] }} />
        <Box
          sx={{
            background: "white",
            padding: 1,
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <TextField
            sx={{ width: "100%" }}
            variant="filled"
            label="프로젝트 찾기"
            size="small"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ height: 3, backgroundColor: grey[200]}} />
        <Box sx={{ height: "100%", position: "relative", maxWidth: "350", minWidth: "350" }}>
          {projects.map((project) => {
            return (
              <Box key={project.id}>
                <Box
                  key={project.id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flex: 1,
                    paddingX: 1,
                  }}
                >
                  <Box key={project.id} sx={{ padding: 2 }}>
                    <Typography noWrap>{project.name}</Typography>
                    <Typography noWrap variant="caption">
                      {project.description}
                    </Typography>
                  </Box>
                  <Box>
                    <DenseIconButton
                      onClick={(e) => {
                        setProject(project);
                        setOpen(false);
                        preventAndStopPropagation(e);
                      }}
                    >
                      <OpenProjectIcon />
                    </DenseIconButton>
                    <MoreBtn project={project} />
                  </Box>
                </Box>
                <Box sx={{ height: 5, backgroundColor: grey[200] }}></Box>
              </Box>
            );
          })}
          <InnerLoading loading={onProjectsLoading} />
        </Box>
        <DialogActions>
          <Box
            sx={{
              paddingY: 1,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination count={totalPage} page={page} onChange={onPageChange} />
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
