import React, { useCallback, useEffect, useRef, useState } from "react";
import { View } from "ol";
import Synchronize from "ol-ext/interaction/Synchronize";
import { useNavigate } from "react-router-dom";
import "./no-zoom.css";
import LayerAccordions from "./components/LayerAccordions";
import useSwipeCommonStore, { MODE } from "./stores/useSwipeCommon.store";
import useSwipeMaps from "./hooks/useSwipeMaps";
import ModeToggleBtns from "./components/components/ModeToggleBtns";

export default function OLSyncMap() {
  const map_ref1 = useRef<HTMLDivElement>(null);
  const map_ref2 = useRef<HTMLDivElement>(null);

  const { map1, map2, center, zoom } = useSwipeMaps();
  const { task } = useSwipeCommonStore();
  const navigate = useNavigate();

  // 새로고침되면 복구
  useEffect(() => {
    if (!task) navigate("/");
  }, [task, navigate]);

  const genView = useCallback(() => {
    const view = new View({
      center,
      zoom,
    });

    return view;
  }, [center, zoom]);

  useEffect(() => {
    map1.setView(genView());
  }, [map1, genView]);

  useEffect(() => {
    map2.setView(genView());
  }, [map2, genView]);

  useEffect(() => {
    // set current
    map1.setTarget(map_ref1.current as HTMLDivElement);
    map2.setTarget(map_ref2.current as HTMLDivElement);
    // Synchronize the maps
    map1.addInteraction(new Synchronize({ maps: [map2] }));
    map2.addInteraction(new Synchronize({ maps: [map1] }));
    return () => {
      map1.setTarget("");
      map2.setTarget("");
    };
    // dependency array에 task 가 없으면 이후에 지도 sync 문제가 생김
  }, [map1, map2, task]);

  const { mode } = useSwipeCommonStore();

  return (
    <div
      style={{
        display: "flex",
        height: "calc(100dvh - 48px)",
        width: "100wh",
        position: "relative",
      }}
    >
      <div ref={map_ref1} style={{ height: "100%", width: mode === "compare" ? "50%" : "0%" }} />
      <div ref={map_ref2} style={{ height: "100%", width: mode === "compare" ? "50%" : "100%" }} />
      <ModeToggleBtns />
      <LayerAccordions />
    </div>
  );
}
