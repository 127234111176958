import * as React from "react";
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  Box,
  Typography,
  styled,
  AccordionProps,
  LinearProgress,
  LinearProgressPropsColorOverrides,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import proj4 from "proj4";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import { OverridableStringUnion } from "@mui/types";
import {
  InputEntity,
  OutputEntity,
  ResourceEntity,
  TaskEntity,
} from "../../../../clients/MainApiClient/dtos/entities";
import useOrthophotoMapStore from "../../../../pages/OrthophotoMapPage/orthophoto-map.store";
import ResourceListItem, { DenseChip } from "../../../../design/atoms/ResourceListItem";
import DenseIconButton from "../../../../design/atoms/buttons/DenseIconButton";
import { EditLoadIcon, MapIcon } from "../../../../design/atoms/icons";
import ProjectListAccordionItemSummary from "../../../../design/molecules/ProjectListAccordionItem";
import TaskItemMoreBtn from "./components/TaskItemMoreBtn";
import useSwipeCommonStore from "../../../../pages/SwipePage/stores/useSwipeCommon.store";

function BaseTiffResourceItem({ input }: { input: InputEntity }) {
  const navigate = useNavigate();
  const { setOrthoMapUrl, setZoom, setCenter, setExtent } = useOrthophotoMapStore();

  const handleToMapClick = (resource: ResourceEntity) => {
    setOrthoMapUrl(`/api/resources/${resource.id}/tiles/{z}/{x}/{-y}`);
    // setZoom(13);
    if (!resource.orthophoto) return;
    if (!resource.orthophoto.center) return;
    if (!resource.orthophoto.center.coordinates) return;
    if (resource.orthophoto.center.coordinates.length !== 2) return;
    //
    const center = resource.orthophoto.center.coordinates as [number, number];
    const center2 = proj4(`EPSG:4326`, `EPSG:3857`, center);
    setCenter(center2);
    //
    const bbox = resource.orthophoto.bbox.coordinates;
    const bboxPolygon = bbox[0].map((coord) => proj4(`EPSG:4326`, `EPSG:3857`, coord));
    const bboxWidth = Math.abs(bboxPolygon[2][0] - bboxPolygon[0][0]);
    const bboxHeight = Math.abs(bboxPolygon[2][1] - bboxPolygon[0][1]);
    const maxDimension = Math.max(bboxWidth, bboxHeight);
    const zoom = Math.floor(20 - Math.log2(maxDimension / 128));

    //
    setZoom(zoom > 18 ? 18 : zoom);
    const extent = [bboxPolygon[0][0], bboxPolygon[0][1], bboxPolygon[2][0], bboxPolygon[2][1]] as [
      number,
      number,
      number,
      number,
    ];
    setExtent(extent);

    if (window.location.pathname !== "/orthophoto-map") {
      navigate("/orthophoto-map");
    }
  };

  return (
    <Box
      sx={{
        paddingLeft: "16px",
        paddingTop: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "4px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.5px",
          color: "#000000",
          userSelect: "none",
        }}
      >
        {input.name}
      </Typography>
      <ResourceListItem>
        <DenseChip type={input.resource.file_name.split(".").pop() || "unknown"} />
        <Box>
          <div style={{ userSelect: "none" }}>{input.resource.name}</div>
          <div style={{ userSelect: "none", fontSize: "14px", color: "#565656" }}>
            {input.resource.file_name}
          </div>
        </Box>
        <div style={{ flexGrow: 1 }} />
        <DenseIconButton onClick={() => handleToMapClick(input.resource)}>
          <MapIcon />
        </DenseIconButton>
      </ResourceListItem>
    </Box>
  );
}

function ChangeDetectionItem({ task, output }: { task: TaskEntity; output: OutputEntity }) {
  const { setTask } = useSwipeCommonStore();
  const navigate = useNavigate();

  const showSwipeMap = () => {
    setTask(task);
    if (window.location.pathname !== "/swipe") navigate("/swipe");
  };
  return (
    <Box
      sx={{
        paddingLeft: "16px",
        paddingTop: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "4px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.5px",
          color: "#000000",
          userSelect: "none",
        }}
      >
        {output.name}
      </Typography>
      <ResourceListItem>
        <DenseChip type="gpkg" />
        <Box>
          <div style={{ userSelect: "none" }}>{output.name}</div>
          <div style={{ userSelect: "none", fontSize: "14px", color: "#565656" }}>
            {output.description}
          </div>
        </Box>
        <div style={{ flexGrow: 1 }} />
        <DenseIconButton
          onClick={() => {
            showSwipeMap();
          }}
        >
          <EditLoadIcon />
        </DenseIconButton>
      </ResourceListItem>
    </Box>
  );
}

export default function TaskAccordion({ task }: { task: TaskEntity }) {
  const [eeee, setEeee] = React.useState(false);

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(() => ({
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
    backgroundColor: "transparent",
  }));

  const [progressColor, setProgressColor] =
    React.useState<
      OverridableStringUnion<
        "primary" | "secondary" | "error" | "info" | "success" | "warning" | "inherit",
        LinearProgressPropsColorOverrides
      >
    >("primary");
  React.useEffect(() => {
    switch (task.status) {
      case "failed":
        setProgressColor("error");
        break;
      case "processing":
        setProgressColor("primary");
        break;
      default:
        setProgressColor("inherit");
        break;
    }
  }, [task.status]);

  return (
    <Accordion sx={{ width: "100%" }} expanded={eeee} onChange={() => setEeee(!eeee)}>
      <Box>
        <ProjectListAccordionItemSummary>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                marginLeft: "8px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "4px",
                }}
              >
                <Typography>{task.name}</Typography>
                <DenseChip type={task.type.name.split(".").pop() || ""} />
              </Box>
              <div style={{ flexGrow: 1 }} />
              {task.status === "failed" && <RunningWithErrorsIcon color="error" />}
              {/* <DenseIconButton onClick={handleClick}>
            <MapIcon />
          </DenseIconButton> */}
              <TaskItemMoreBtn task={task} />
            </Box>
          </Box>
        </ProjectListAccordionItemSummary>
        {(task.status === "processing" || task.status === "pending") && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress color={progressColor} />
          </Box>
        )}
      </Box>

      <AccordionDetails>
        {task.inputs
          .sort((a, b) => a.order - b.order)
          .map((input) => (
            <BaseTiffResourceItem key={input.id} input={input} />
          ))}

        {task.outputs.map((output) => (
          <ChangeDetectionItem key={output.id} task={task} output={output} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
