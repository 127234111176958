import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, BoxProps, ListItem, Stack, Typography } from "@mui/material";
import { ResourceEntity } from "../../../../../clients/MainApiClient/dtos/entities";
import MainApiClient from "../../../../../clients/MainApiClient";

export default function ResourceSearchInput(
  props: BoxProps & {
    onResourceChanged: (resource: ResourceEntity | null) => void;
  },
) {
  const { onResourceChanged, ...restProps } = props;
  const client = React.useMemo(() => new MainApiClient(), []);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<ResourceEntity[]>([]);
  const [resource, setResource] = React.useState<ResourceEntity | null>(null);

  const search = React.useCallback(
    (params: { q?: string; page?: number; per_page?: number }) => client.readResources(params),
    [client],
  );

  const handleLoadMore = async (q: string) => {
    setOptions([]);
    if (!q) {
      return;
    }
    setLoading(true);
    // 추가 옵션을 로드하는 로직 추가
    search({ q, page: 1, per_page: 100 })
      .then(({ resources: fetchedResources }) => {
        setOptions(fetchedResources);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    console.log("DFDFDF");

    if (resource) onResourceChanged(resource);
  }, [resource]);

  return (
    <Box {...restProps}>
      <Autocomplete
        value={resource}
        options={options}
        getOptionLabel={(option) => `${option.name} (${option.file_name})`}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        renderOption={(ps, option) => (
          <ListItem {...ps} key={option.id}>
            <Stack direction="column">
              <Typography>{option.name}</Typography>
              <Typography variant="caption">{option.file_name}</Typography>
            </Stack>
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Search" variant="outlined" fullWidth />
        )}
        loading={loading}
        onChange={(event, rewResource) => {
          if (rewResource !== null) {
            setResource(rewResource);
          }
        }}
        onInputChange={(event, newQuery) => {
          handleLoadMore(newQuery);
        }}
      />
    </Box>
  );
}
