import { create } from "zustand";

interface ConfidenceState {
  conf: number[];
  setConf: (conf: number[]) => void;
  handleChangeConf: (event: Event, newValue: number | number[], activeThumb: number) => void;
}

const useConfidenceStore = create<ConfidenceState>((set) => ({
  conf: [0, 100],
  setConf: (conf) => set({ conf }),
  handleChangeConf: (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < 10) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - 10);
        set({ conf: [clamped, clamped + 10] });
      } else {
        const clamped = Math.max(newValue[1], 10);
        set({ conf: [clamped - 10, clamped] });
      }
    } else {
      set({ conf: newValue as number[] });
    }
  },
}));

export default useConfidenceStore;
