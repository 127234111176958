import { Map } from "ol";
import Swipe from "ol-ext/control/Swipe";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";
import { useEffect, useMemo } from "react";
import proj4 from "proj4";
import useVectorSourceStore from "../stores/useVectorSource.store";
import useSwipeCommonStore from "../stores/useSwipeCommon.store";
import useSwipeLayerStore from "../stores/useSwipeLayer.store";

//
function createBaseMap() {
  const map = new Map({
    layers: [
      new TileLayer({
        source: new XYZ({
          url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
          maxZoom: 19,
        }),
      }),
    ],
  });
  return map;
}

export default function useSwipeMaps() {
  const { task } = useSwipeCommonStore();
  const { vectorSource, setUrl, mainBar, undoInteraction } = useVectorSourceStore();
  const { tileLayer1, tileLayer2_1, tileLayer2_2, vectorLayer, setTask, setSource } =
    useSwipeLayerStore();

  // 기본적으로 있는 것
  const map1 = useMemo(() => {
    return createBaseMap();
  }, []);
  const map2 = useMemo(() => {
    return createBaseMap();
  }, []);

  useEffect(() => {
    if (!task) return;
    setTask(task);
    const output = task.outputs[0];
    setUrl(`api/projects/${task.project_id}/tasks/${task.id}/outputs/${output.id}/features`);
  }, [setTask, setUrl, task]);

  useEffect(() => {
    if (!vectorSource) return;
    setSource(vectorSource);
  }, [vectorSource, setSource]);

  const [zoom, center] = useMemo(() => {
    let ct = [0, 0];
    let zm = 13;
    if (!task) return [zm, ct];
    // console.log(task);

    const { resource: resource1 } = task.inputs[0];
    if (!resource1) return [zm, ct];
    if (!resource1.orthophoto) return [zm, ct];

    if (resource1.orthophoto.center && resource1.orthophoto.center.coordinates) {
      const coordinates = resource1.orthophoto.center.coordinates as [number, number];

      const centerCandi = (coordinates as [number, number]) || [0, 0];
      ct = proj4(`EPSG:4326`, `EPSG:3857`, centerCandi);
    }

    if (!resource1.orthophoto.bbox) return [zm, ct];

    const bbox = resource1.orthophoto.bbox.coordinates;

    if (!bbox) return [zm, ct];

    const bboxPolygon = bbox[0].map((coord) => proj4(`EPSG:4326`, `EPSG:3857`, coord));
    const bboxWidth = Math.abs(bboxPolygon[2][0] - bboxPolygon[0][0]);
    const bboxHeight = Math.abs(bboxPolygon[2][1] - bboxPolygon[0][1]);
    const maxDimension = Math.max(bboxWidth, bboxHeight);
    zm = Math.floor(20 - Math.log2(maxDimension / 128));
    zm = zm > 18 ? 18 : zm;

    return [zm, ct];
  }, [task]);

  const swipe = useMemo(() => {
    const s = new Swipe();
    s.set("orientation", "horizontal");
    return s;
  }, []);

  useEffect(() => {
    swipe.addLayer(tileLayer2_1, false);
    swipe.addLayer(tileLayer2_2, true);

    return () => {
      swipe.removeLayer(tileLayer2_1);
      swipe.removeLayer(tileLayer2_2);
    };
  }, [swipe, tileLayer2_1, tileLayer2_2]);

  const { useTool } = useSwipeCommonStore();
  useEffect(() => {
    if (mainBar) {
      mainBar.setVisible(useTool);
    }
  }, [mainBar, useTool]);

  // ma1 ----------
  useEffect(() => {
    map1.addLayer(tileLayer1);
    return () => {
      map1.removeLayer(tileLayer1);
    };
  }, [map1, tileLayer1]);

  useEffect(() => {
    map1.addLayer(vectorLayer);
    return () => {
      map1.removeLayer(vectorLayer);
    };
  }, [map1, vectorLayer]);

  useEffect(() => {
    if (!undoInteraction) return () => {};
    if (!map1.getInteractions().getArray().includes(undoInteraction))
      map1.addInteraction(undoInteraction);
    return () => {
      map1.removeInteraction(undoInteraction);
    };
  }, [map1, undoInteraction]);

  useEffect(() => {
    if (mainBar) {
      map1.addControl(mainBar);
    }
    return () => {
      if (mainBar) map1.removeControl(mainBar);
    };
  }, [map1, mainBar]);
  // ma1 ----------

  // ma2 ----------
  useEffect(() => {
    map2.addLayer(tileLayer2_1);
    map2.addLayer(tileLayer2_2);
    return () => {
      map2.removeLayer(tileLayer2_1);
      map2.removeLayer(tileLayer2_2);
    };
  }, [map2, tileLayer2_1, tileLayer2_2]);

  useEffect(() => {
    if (!map2.getLayers().getArray().includes(vectorLayer)) map2.addLayer(vectorLayer);
    return () => {
      map2.removeLayer(vectorLayer);
    };
  }, [map2, vectorLayer]);

  useEffect(() => {
    if (!undoInteraction) return () => {};
    map2.addInteraction(undoInteraction);
    return () => {
      map2.removeInteraction(undoInteraction);
    };
  }, [map2, undoInteraction]);

  useEffect(() => {
    if (mainBar) {
      map2.addControl(mainBar);
    }
    return () => {
      if (mainBar) {
        map2.removeControl(mainBar);
      }
    };
  }, [map2, mainBar]);
  // ma2 ----------

  const { mode } = useSwipeCommonStore();

  useEffect(() => {
    map2.removeControl(swipe);
    if (mode) {
      switch (mode) {
        case "swipev":
        case "swipeh":
          map2.addControl(swipe);
          swipe.set("orientation", mode === "swipev" ? "vertical" : "horizontal");
          break;
        default:
          break;
      }
    }
    if (map2) map2.updateSize();
    if (map1) map1.updateSize();
  }, [map1, map2, mode, swipe]);

  return {
    //
    map1,
    map2,
    //
    zoom,
    center,
  };
}
